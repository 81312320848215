<template>
	<div data-component="focus-keyword-fields">
		<select-field
			name="Course focus"
			:options="courseFocuses"
			v-if="loadedKeywords && formLoaded"
			:keys="['title', 'title']"
			v-model="getFocus"
			:multiple="true"
		/>
		<select-field
			name="Keywords"
			v-if="loadedKeywords && formLoaded"
			:options="getKeywords"
			:keys="['title', 'title']"
			v-model="getKeyword"
			:multiple="true"
		/>
	</div>
</template>

<script>

	import api          from '@/services/api';
	import SelectField  from '@/components/forms/SelectField';

	export default {
		components: {
			SelectField
		},
		props: {
			focusVal: {
				type: Array,
				default: undefined
			},
			keywordsVal: {
				type: Array,
				default: undefined
			},
			formLoaded: {
				type: Boolean,
				default: undefined
			},
			entryRoute: {
				type: String,
				default: ''
			}
		},
		data: () => ({
			loadedKeywords:  false,
			courseFocuses:   [],
			keywords:        [],
			focuses: 				 [],
			focus: 					 [],
			keyword: 			   []
		}),
		computed: {
			getKeywords () {
				return this.focus.flatMap((focus) => {
					return this.keywords.filter(item => item.tag === focus);
				});
			},
			collectKeywords () {
				return this.focus.flatMap((focus) => {
					return this.keywords.map((word) => {
						if (focus === word.tag && this.keyword.includes(word.title)) {
							return word.title;
						}
					});
				});
			},
			getFocus: {
				get () {
					if (!this.focus) {
						return [];
					}
					return this.focus;
				},
				set (value) {
					this.$emit('onFocus', value);
					this.focuses = this.collectKeywords;
					this.focus = value;
				}
			},
			getKeyword: {
				get () {
					if (!this.keyword) {
						return [];
					}
					return this.keyword;
				},
				set (value) {
					this.$emit('onKeyword', value);
					this.keyword = value;
				}
			}
		},
		created () {
			this.initFocus();
		},
		mounted () {
			this.focus = this.focusVal;
			this.keyword = this.keywordsVal;
		},
		methods: {
			async initFocus () {
				const focus = await api[this.entryRoute].getAllFocuses();
				if (!focus) {
					return;
				}
				this.courseFocuses = focus;
				this.tagKeywords();
			},
			tagKeywords () {
				this.keywords = this.courseFocuses.flatMap((focus) => {
					return focus.keywords.map((obj) => {
						return {
							...obj,
							tag: focus.title
						};
					});
				});
				this.loadedKeywords = true;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='focus-keyword-fields'] {
		display:flex;
		flex-direction:column;
	}

</style>
