<template>
	<div data-component="range-field">
		<validation-provider
			v-slot="{ errors, required }"
			:rules="rules"
			:name="getName"
		>
			<label
				data-element="label"
				:for="id"
				v-html="getLabel"
				:data-required="required"
				v-if="hasLabel"
			>
				{{ label }}
			</label>
			<vue-slider
				ref="vslider"
				data-element="v-slider"
				v-model="intVal"
				v-bind="getSliderProps"
				@change="onChange"
			/>
			<p
				v-if="errors[0]"
				data-element="error"
			>
				{{ errors[0] }}
			</p>
		</validation-provider>
	</div>
</template>

<script>
	import VueSlider from 'vue-slider-component';
	import 'vue-slider-component/theme/antd.css';

	export default {
		components: {
			VueSlider
		},
		props: {
			name: {
				type: String,
				required: true
			},
			id: {
				type: String,
				default: ''
			},
			value: {
				type: [String, Number, Array],
				default: ''
			},
			labelMarks: {
				type: Array,
				default: undefined
			},
			lazy: {
				type: Boolean,
				default: false
			},
			options: {
				type: Array,
				default: () => []
			},
			optionLabel: {
				type: Array,
				default: () => []
			},
			rules: {
				type: String,
				required: true
			},
			hasLabel: {
				type: Boolean,
				default: true
			},
			label: {
				type: String,
				default: ''
			},
			showRail: {
				type: Boolean,
				default: undefined
			}
		},
		data: () => ({
			intVal: undefined
		}),
		computed: {
			getName () {
				return this.name;
			},
			getLabel () {
				return this.name;
			},
			marks () {
				if (!this.labelMarks) {
					return;
				}
				return this.labelMarks;
			},
			getSliderProps () {
				const props = {
					contained: true,
					adsorb: true,
					marks: this.marks,
					lazy: this.lazy,
					data: this.options,
					'data-value': this.optionLabel[0],
					'data-label': this.optionLabel[1]
				};
				if (this.showRail === false) {
					props.process = false;
				}
				return props;
			}
		},
		mounted () {
			this.intVal = this.value;
		},
		methods: {
			onChange () {
				const val = this.$refs.vslider.getValue();
				this.$emit('input', val);
			},
			setValue (val) {
				this.$refs.vslider.setValue(val);
			}
		}
	};
</script>

<style lang="scss" scoped>
	[data-component='range-field'] {
		display:block;
		margin-bottom:rem(40);
		box-sizing:content-box;
		[data-element="v-slider"] {
		}
	}
</style>
