<template>
	<div
		data-component="text-note"
	>
		<h3
			v-html="getLabel"
		/>
		<p
			v-html="value"
		/>
	</div>
</template>

<script>
	export default {
		props: {
			name: {
				type: String,
				required: true
			},
			value: {
				type: [
					String,
					Number
				],
				default: undefined
			}
		},
		computed: {
			getLabel () {
				return this.name;
			}
		}
	};

</script>

<style lang="scss" scoped>

  [data-component='text-note'] {
		margin-bottom: rem(12);

		h3 {
			font-size:rem(12);
			padding-bottom:rem(6);
			display: inline-block;
			font-weight: 700;
		}
		p {
			width:100%;
			outline:none;
			border-radius:0;
			font-size:rem(12);
			line-height:32px;
			text-transform: capitalize;
		}
	}

</style>
